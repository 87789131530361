export const services = [
	{
		id: '1',
		name: 'إنشاء المشاريع السكنية',
		content:
			'نقدم خبرتنا في إنشاء وتنفيذ مشاريع سكنية متكاملة، بدءًا من الفلل الفاخرة وصولاً إلى الاستراحات والمجمعات السكنية.',
	},
	{
		id: '2',
		name: 'بناء المباني التجارية',
		content:
			'نحن متخصصون في إنشاء مباني تجارية تلبي احتياجات الأعمال وتعكس هوية الشركات بتصميمات فريدة وجودة عالية.',
	},
	{
		id: '3',
		name: 'ترميم وتجديد',
		content:
			'خدمات الترميم والتجديد لتحسين وتجديد المساحات القائمة، مع التركيز على تحسين الوظائف والجمالية.',
	},
	{
		id: '4',
		name: 'توريد مواد البناء',
		content:
			'نقدم خدمة توريد مواد البناء عالية الجودة، مع اهتمام خاص بتوفير أحدث وأفضل المواد في السوق.',
	},
	{
		id: '5',
		name: 'تشطيبات وديكورات',
		content:
			'خبراء في تقديم خدمات التشطيب بأعلى معايير الجودة، بالإضافة إلى خيارات ديكورية تلبي ذوق واحتياجات العملاء.',
	},
	{
		id: '6',
		name: 'خدمات العوازل والصيانة',
		content:
			'خدمات شاملة للعوازل بأنواعها، مع إمكانية توفير خدمات الصيانة للحفاظ على استدامة المشاريع.',
	},
];
