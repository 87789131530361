export const projects = [
	{
		id: '1',
		image: 'IMG-20230227-WA0021',
		content:
			'المشاركة في أعمال الحفر للبنية التحتية لمشروع جامعة الملك سعود بالرياض',
	},
	{
		id: '2',
		image: 'IMG-20230227-WA0023',
		content: 'أعمال إنشاءات فلل في مشروع بوابة الشرق بالرياض',
	},
	{
		id: '3',
		image: 'IMG-20230227-WA0022',
		content: 'انشاء عمارة سكنية في المجمعة',
	},
	{
		id: '4',
		image: 'IMG-20231111-WA0004',
		content: 'حفر وانشاء خزان مياه في البوليفارد بالرياض',
	},
];
