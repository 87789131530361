import { icons } from '../utils/icons';
export const features = [
	{
		id: '1',
		icon: icons.clock,
		name: 'السرعة',
		content:
			'نحرص على احترام المواعيد الزمنية للمشاريع، مما يضمن تسليمها في الوقت المحدد.',
	},
	{
		id: '2',
		icon: icons.certificate,
		name: 'الجودة',
		content:
			'نلتزم بأعلى معايير الجودة في عمليات البناء، مع التركيز على الاستدامة والمتانة',
	},
	{
		id: '3',
		icon: icons.rank,
		name: 'التميز',
		content:
			'نعكس رؤيتك بإبداع وتميز في التصميم الهندسي، مضيفين لمسة فنية وفريدة لكل مشروع',
	},
	{
		id: '4',
		icon: icons.hands,
		name: 'علاقات مستدامة',
		content:
			'نقوم ببناء علاقات قائمة على الثقة والجودة، ونسعى لتحقيق رضا العملاء في كل تفاصيل المشروع',
	},
	{
		id: '5',
		icon: icons.city,
		name: 'تنوع المشاريع',
		content:
			'نقدم خبرة وتخصص في تنفيذ مشاريع متنوعة، بما في ذلك فلل، عمارات، استراحات، ومشاريع أخرى',
	},
	{
		id: '6',
		icon: icons.tools,
		name: 'تكنولوجيا البناء',
		content:
			'نعتمد أحدث التقنيات في عمليات البناء والتشطيب، مما يسهم في تحسين كفاءة العمل والجودة',
	},
];
